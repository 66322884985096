import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"

const Fallback = (props) => {
  console.warn(`No component found for: ${props.blocktype}`)
  return false
}

export default function About(props) {
  const landingpagePage = props.data.contentfulLandingpagePage

  return (
    <Layout {...landingpagePage}>
      {landingpagePage.blocks.map((block, i) => {
        const Component = sections[block.blocktype] || Fallback
        return <Component key={block.id} index={i} {...block} />
      })}
    </Layout>
  )
}

export const query = graphql`
  query landingPage($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }    
    contentfulLandingpagePage(node_locale: {eq: $language}){
      id
      title
      description
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        # New component fragment
        ...HomepageProductConfigContent
      }
    }
  }
`
